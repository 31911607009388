import React, { Component } from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import TitleStyle from '../TitleStyle'
import {
  DribbbleOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LinkedinOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { PostData, SERVER_UPLOAD } from '../../api/service'

class QuarterlyReportView extends Component {
  state = {
    title: '',
    active: '',
    value: null,
    isLoaded: false,
  }
  componentDidMount() {
    this.setState({
      ...this.props,
      isLoaded: true,
    })
    //this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      //console.log(this.props)
      this.setState({
        ...this.props,
        isLoaded: true,
      })
    }

    // if (this.props.qr !== prevProps.qr) {
    //   //console.log(this.props)
    //   this.loadData()
    // }
  }



  

  render() {
    return (
      <div style={{ overflowX: 'auto' }}>
        {/* Courses Covered */}
        
          

        {this.state.active == '1' && this.state.isLoaded && (
          <>
    
            {Array.isArray(this.state.courses_covered ) &&
              this.state.courses_covered.length > 0 && (
                <>
                  <TitleStyle title={this.state.title} />
                  <br />

                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td>#</td>

                        <td>Year/Quarter</td>
                        <td>Paper/Course</td>
                        <td>Topic Discussed</td>

                        <td>Remark (if Any)</td>
                      </tr>
                    </thead>

                    <tbody>
                      {Array.isArray(this.state.courses_covered) &&
                        this.state.courses_covered.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.quarter}</td>

                            <td>
                              {el.paper}
                              <br />
                              {Array.isArray(el.file) &&
                                el.file.map((el1, index1) => (
                                  <div key={index1}>
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: 'blue', fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  </div>
                                ))}
                            </td>
                            <td>{el.topic}</td>
                            <td>{el.remark}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
          </>
        )}

        

        {this.state.active == '5' && this.state.isLoaded && (
          <div>

{Array.isArray(this.state.research) &&
              (this.state.research).length > 0 && (
                <>
                  <TitleStyle title={this.state.title} />
                  <br />
                  <table width="100%" className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Title of the project 1</td>
                        <td>Funding Agency</td>
                        <td>Duration of the Project</td>
                        <td>Project Cost (in Lakh)</td>
                        <td>Co-investigator (if any)</td>
                        <td>Status</td>
                        <td>Date of Commencement</td>
                        <td>Date of Completion</td>
                        <td>Year/Quarter</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(this.state.research) &&
                        (this.state.research).map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {el.title}
                              <br />
                              {Array.isArray(el.file) &&
                                el.file.map((el1, index1) => (
                                  <div key={index1}>
                                    <a
                                      target="_blank"
                                      href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                      style={{ color: 'blue', fontSize: 10 }}
                                    >
                                      <LinkOutlined /> Attachment {index1 + 1}
                                    </a>
                                  </div>
                                ))}
                            </td>
                            <td>{el.sponsored_by}</td>
                            <td>{el.duration}</td>
                            <td>{el.fund}</td>
                            <td>{el.co_researcher}</td>
                            <td>{el.status}</td>
                            <td>{el.commencement_date}</td>
                            <td>{el.submission_date}</td>
                            <td>{el.quarter}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            <br />

            {Array.isArray(this.state.research_paper) &&
              (this.state.research_paper).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Research Publications
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the paper</td>

                      <td>Name of the Book/Journal</td>
                      <td>Published By</td>
                      <td>ISBN/ISSN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Date / Year</td>
                      <td>Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    
                    
                    
                    {Array.isArray(this.state.research_paper) && this.state.research_paper.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.page_nos}</td>
                          <td> {el.date_year}</td>
                          <td> {el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.other_publication) &&
              (this.state.other_publication).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Other Publication with details
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the Publication</td>
                      <td>Published By</td>
                      <td>ISBN / ISSN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td>Any other information</td>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.isArray(this.state.other_publication) &&
                      (this.state.other_publication).map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_book}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.page_nos}</td>
                          <td> {el.date_year}</td>
                          <td> {el.quarter}</td>
                          <td>{el.other_info}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}


            {Array.isArray(this.state.text_book) &&
              (this.state.text_book).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Text Book/Book
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Name of the Book/Text Book</td>
                      <td>Published By</td>
                      <td>ISBN</td>
                      <td>Volume</td>
                      <td>Edition</td>
                      <td>Name of authors</td>
                      <td>Year</td>
                      <td>Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.text_book) &&
                      (this.state.text_book).map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_book}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.edition}</td>
                          <td>{el.name_of_authors}</td>
                          <td>{el.date_year}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.magazine) &&
              (this.state.magazine).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={7} align="center">
                        Book/Journal/ Magazine Edited
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the Book/journal/Magazine</td>
                      <td>Published By</td>
                      <td>ISBN / ISSN</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td>Any other Information</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.magazine) &&
                      (this.state.magazine).map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_book}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td> {el.date_year}</td>
                          <td> {el.quarter}</td>

                          <td>{el.other_info}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.popular_book) &&
              (this.state.popular_book).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={9} align="center">
                        Article in Popular Book/Magazine/Edited Book
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>

                      <td>Title of the paper</td>

                      <td>Title of the Book/Magazine/Edited Book</td>
                      <td>Published By</td>
                      <td>ISBN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Year</td>
                      <td>Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.popular_book) &&
                      (this.state.popular_book).map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.published_by}</td>
                          <td>{el.isbn_no}</td>
                          <td>{el.volume}</td>
                          <td>{el.page_nos}</td>
                          <td>{el.date_year}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.news_paper) &&
              (this.state.news_paper).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={5} align="center">
                        Publication in News Paper
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>
                      <td>Date / Year</td>

                      <td>Title of the Article</td>

                      <td>Name of the News Paper</td>
                      <td>Year/Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.news_paper) &&
                     (this.state.news_paper).map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {el.date_year}</td>
                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

            {Array.isArray(this.state.econtent) &&
              (this.state.econtent).length > 0 && (
                <table width="100%" className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <td colSpan={7} align="center">
                        E-CONTENT DEVELOPMENT
                      </td>
                    </tr>
                    <tr>
                      <td>#</td>
                      <td>Year</td>

                      <td>Type of E-content</td>
                      <td>Title of the Publication</td>
                      <td>Published By</td>

                      <td>Remarks</td>
                      <td>Year/Quarter</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(this.state.econtent) &&
                      (this.state.econtent).map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {el.date_year}</td>

                          <td>
                            {el.title_paper}
                            <br />
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ color: 'blue', fontSize: 10 }}
                                  >
                                    <LinkOutlined /> Attachment {index1 + 1}
                                  </a>
                                </div>
                              ))}
                          </td>
                          <td>{el.title_book}</td>
                          <td>{el.published_by}</td>
                          <td>{el.other_info}</td>
                          <td>{el.quarter}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
          </div>
        )}

        {/* Extension Activity */}
        {this.state.active == '2' &&
          ((Array.isArray(this.state.social_activities) &&
              (this.state.social_activities).length > 0)) && (
            <div>
              {Array.isArray(this.props.qr == "ALL" ? this.state.social_activities : this.state.social_activities.filter(el => el.quarter == this.props.qr)) &&
                (this.props.qr == "ALL" ? this.state.social_activities : this.state.social_activities.filter(el => el.quarter == this.props.qr)).length > 0 && (
                  <>
                    <TitleStyle title={this.state.title} />

                    <br />

                    <table
                      width="100%"
                      className="table table-sm table-bordered"
                    >
                      <thead>
                        <tr>
                          <td colSpan={7} align="center">
                            Extension Activities
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>#</td>
                          <td rowSpan={2}>Name of the Committee / Scheme</td>

                          <td rowSpan={2}>
                            Position/Responsibility/ Capacity Hold
                          </td>

                          <td colSpan={2}>Period</td>
                          <td rowSpan={2}>Other Information</td>
                          <td rowSpan={2}>Year & Quarter</td>
                        </tr>
                        <tr>
                          <td>From</td>
                          <td>To</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.props.qr == "ALL" ? this.state.social_activities : this.state.social_activities.filter(el => el.quarter == this.props.qr)) &&
                          (this.props.qr == "ALL" ? this.state.social_activities : this.state.social_activities.filter(el => el.quarter == this.props.qr)).map((el, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {' '}
                                {el.name}
                                {Array.isArray(el.file) &&
                                  el.file.map((el1, index1) => (
                                    <div key={index1}>
                                      <a
                                        target="_blank"
                                        href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                        style={{ color: 'blue', fontSize: 10 }}
                                      >
                                        <LinkOutlined /> Attachment {index1 + 1}
                                      </a>
                                    </div>
                                  ))}
                              </td>
                              <td>{el.position}</td>
                              <td>{el.from}</td>
                              <td>{el.till}</td>
                              <td>{el.other_info}</td>
                              <td>{el.quarter}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
            </div>
          )}

        {/* In-Campus Activity */}
        {this.state.active == '4' &&
          (Array.isArray(this.state.college_activities) &&
            (this.state.college_activities).length > 0) && (
            <div>
              {Array.isArray(this.state.college_activities) &&
                (this.state.college_activities).length > 0 && (
                  <>
                    <TitleStyle title={this.state.title} />

                    <br />
                    <table
                      width="100%"
                      className="table table-sm table-bordered"
                    >
                      <thead>
                        <tr>
                          <td colSpan={7} align="center">
                            In Campus Activities
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>#</td>
                          <td rowSpan={2}>Name of the Committee / Scheme</td>

                          <td rowSpan={2}>
                            Position/Responsibility/ Capacity Hold
                          </td>

                          <td colSpan={2}>Period</td>
                          <td rowSpan={2}>Other Information</td>
                          <td rowSpan={2}>Year/Quarter</td>
                        </tr>
                        <tr>
                          <td>From</td>
                          <td>To</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(this.state.college_activities) &&
                          (this.state.college_activities).map((el, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td> {el.name}</td>
                              <td>{el.position}</td>
                              <td>{el.from}</td>
                              <td>{el.till}</td>
                              <td>{el.other_info}</td>
                              <td>{el.quarter}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
            </div>
          )}

        {this.state.active == '3' &&
          Array.isArray(this.state.student_related_activity) &&
          (this.state.student_related_activity).length > 0 && (
            <>
              <TitleStyle title={this.state.title} />

              <br />
              <table width="100%" className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Quarter</td>
                    <td>Activity Details with Date </td>
                    <td>No of students participated/involved </td>
                    <td>Remark (if Any) </td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(this.state.student_related_activity) &&
                    (this.state.student_related_activity).map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el.quarter}</td>
                        <td>
                          {' '}
                          {el.activity}
                          <br />
                          {Array.isArray(el.file) &&
                            el.file.map((el1, index1) => (
                              <div key={index1}>
                                <a
                                  target="_blank"
                                  href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                  style={{ color: 'blue', fontSize: 10 }}
                                >
                                  <LinkOutlined /> Attachment {index1 + 1}
                                </a>
                              </div>
                            ))}
                        </td>
                        <td>{el.student_count}</td>
                        <td>{el.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
      </div>
    )
  }
}

export default QuarterlyReportView
