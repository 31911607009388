import React, { Component } from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import TitleStyle from '../TitleStyle';

class ContactPage extends Component {
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Contact`}
                    />

                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={6} md={6}>

                            <Typography
                                dangerouslySetInnerHTML={{ __html: this.props.data.contact_details }}
                            >

                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={12} lg={6} md={6}>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.9591574977094!2d91.68989191502926!3d26.165459583455096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5b1c917bdba1%3A0xb5ba898835797d7f!2sPandu%20College!5e0!3m2!1sen!2sin!4v1641802117421!5m2!1sen!2sin" width="600" height="400" allowfullscreen="" loading="lazy" style={{border: '0px',maxWidth:'100%'}}></iframe>

                        </Grid>
                    </Grid>

                   
                </Container>
            </div>
        );
    }
}

export default ContactPage;