import {
  Divider,
  Grid,
  makeStyles,

  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Button, Select, Tabs } from 'antd'
import Search from 'antd/lib/input/Search'
import React from 'react'
import { GetData, PostData } from '../../api/service'
import Highlighter from 'react-highlight-words';


import { Table, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 10,
    minHeight: '500px',
  },
}))





const { TabPane } = Tabs
const { Option } = Select

export default function IqacMatrics() {
  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  const [group, setGroup] = React.useState('')
  const [groupName, setGroupName] = React.useState('')
  const [types, setTypes] = React.useState([])
  const [isTypeLoaded, setIsTypeLoaded] = React.useState(false)
  let classes = useStyles()

  const [search, setSearch] = React.useState('')

  function fetchData() {
    GetData(`/getgroup`).then((resp) => {
      setData(resp)
      if (resp.length > 0) {
        setGroup(resp[0].id)
        
      }
      setIsLoaded(true)
    })
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  React.useEffect(() => {
    if (group != '') {
      let g = data.filter((el) => el.id == group)
      if (g.length > 0) {
        setTypes(g[0].types)
        setGroupName(g[0].name)
        setIsTypeLoaded(true)
      } else {
        setIsTypeLoaded(false)
      }
    }
  }, [group])


  function searchNow(value) {
    if(value != ''){
      // search now 
    }
  }

  return (
    <div className={classes.root}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h5" style={{fontWeight: 700}}>IQAC Reports</Typography>
        <Typography variant="h6" style={{fontSize: 15}}>Filter Based Search</Typography>
      </div>
      <div style={{ textAlign: 'center' }}>
        {isLoaded && (
          <Select
            value={group}
            style={{ width: 120 }}
            onChange={(e) => setGroup(e)}
          >
            {Array.isArray(data) &&
              data.map((el, index) => (
                <Option
                  style={{ fontWeight: 700, fontSize: 15 }}
                  value={el.id}
                  key={el.id}
                >
                  {el.name}
                </Option>
              ))}
          </Select>
        )}
      </div>

      {/* <div style={{ textAlign: 'center' }}>
        <Search
          placeholder="Input Search Text"
          allowClear
          style={{ width: '50%' }}
          enterButton="Search"
          size="large"
          onSearch={(e) => searchNow(e)}
          
        />
      </div> */}
      <br />
      <Divider />
      <br />
      {isTypeLoaded && (
        <Tabs defaultActiveKey="1" centered>
          {Array.isArray(types) &&
            types.map((el, index) => (
              <TabPane tab={`${el} METRICS`} key={el}>
                <TableView type={el} group={group} groupName={groupName} />
              </TabPane>
            ))}

         
        </Tabs>
      )}

      <br />
      <Divider />
      <br />
    </div>
  )
}

export function TableView({ type, group, groupName }) {

  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false)


  const [data1, setData1] = React.useState([]);
  const [isLoaded1, setIsLoaded1] = React.useState(false)



  const [data2, setData2] = React.useState([]);
  const [isLoaded2, setIsLoaded2] = React.useState(false)


  const [selectedSubGroupId, setSelectedSubGroupId] = React.useState('');
  const [selectedSubGroupName, setSelectedSubGroupName] = React.useState('');


  const [selectedSubSubGroupId, setSelectedSubSubGroupId] = React.useState('');
  const [selectedSubSubGroupName, setSelectedSubSubGroupName] = React.useState('');


  const [show, setShow] = React.useState('CRITERION');


  function fetchData(){
    PostData(`/getsubgroupbygroupidandtype`, {type, group_id: group})
    .then((resp) => {
      setData(resp);
      setIsLoaded(true)
      setShow('CRITERION')
    })
  }

  React.useEffect(() => {
    if(type != '' && group != ''){
      fetchData();
    }
  }, [type, group])






  function getSubSubGroup(){
    PostData(`/getsubsubgroupbysubgroupid`, {sub_group_id : selectedSubGroupId})
    .then((resp) => {
      setData1(resp);
      setIsLoaded1(true)
      setShow('SRNO')
    })
  }

  React.useEffect(() => {
    if(selectedSubGroupId != ''){
      getSubSubGroup();
      let g = data.filter(el => el.id == selectedSubGroupId);
      
      if(g.length > 0){
        setSelectedSubGroupName(g[0].criterion)
      }
    }else{
      setIsLoaded1(false)

    }
  }, [selectedSubGroupId])



  function getFileGroup(){
    PostData(`/getfilegroupbysubsubgroupid`, {sub_sub_group_id : selectedSubSubGroupId})
    .then((resp) => {
      setData2(resp);
      setIsLoaded2(true)
      setShow('FILE')
    })
  }


  React.useEffect(() => {
    if(selectedSubSubGroupId != ''){
      getFileGroup();
      let g = data1.filter(el => el.id == selectedSubSubGroupId);
      
      if(g.length > 0){
        setSelectedSubSubGroupName(g[0].sr_no)
      }
    }else{
      setIsLoaded2(false)
    }
  }, [selectedSubSubGroupId])


  function showImages(upload_info){
//    console.log("🚀 ~ file: IqacMatrics.js ~ line 220 ~ showImages ~ upload_info", upload_info)
    let i = [];
    return <Grid container spacing={2}>
        
        {upload_info.map((el,index) => 
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                {el.fileType === 'image' && <img
                    alt="image"
                    src={el.url}
                    style={{width: '100%'}}
                />}

                {el.fileType === 'file' && <a style={{color: 'blue', fontSize: 12}} href={el.url} target="_blank">View</a>}
            </Grid>
            )}
        
    </Grid>
    
}

const [searchText, setSearchText] = React.useState('')
const [searchedColumn, setSearchedColumn] = React.useState('')

let searchInput = '';

const getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
            // this.setState({
            //   searchText: selectedKeys[0],
            //   searchedColumn: dataIndex,
            // });
          }}
        >
          Close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
  searchedColumn === dataIndex ? (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />
  ) : (
    text
  ),
});


const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
 
};

const handleReset = clearFilters => {
  clearFilters();
  setSearchText('')
           
};


const columns1 = [
  {
    title: 'Criterion',
    dataIndex: 'criterion',
    key: 'criterion',
    width: '20%',
    ...getColumnSearchProps('criterion'),
    render: text => <b>{text}</b>
  },
  {
    title: 'Metric Question',
    dataIndex: 'metric_question',
    key: 'metric_question',
    width: '60%',
    
    ...getColumnSearchProps('metric_question'),
    render: (text, el) => <a style={{color: 'blue'}} onClick={() => {
      setSelectedSubGroupId(el.id)
      setShow('SRNO')
    }}><u>{text}</u></a>
  },
  // {
  //   title: 'Link',
  //   render: (el, row, index) => {
  //     return {
  //       children: <a style={{color: 'blue'}} onClick={() => {
  //         setSelectedSubGroupId(el.id)
  //         setShow('SRNO')
  //       }}><u>Click Here</u></a>,
  
  //     };
  //   },
  // },
];


const columns2 = [
  {
    title: 'Sr No',
    dataIndex: 'sr_no',
    key: 'sr_no',
    width: '20%',
    ...getColumnSearchProps('sr_no'),
    render: text => <b>{text}</b>
  },
  {
    title: 'Metric Question',
    dataIndex: 'metric_question',
    key: 'metric_question',
    width: '60%',
    ...getColumnSearchProps('metric_question'),
    render: (text, el) => <a style={{color: 'blue'}} onClick={() => {
      setSelectedSubSubGroupId(el.id)
          setShow('FILE')
    }}><u>{text}</u></a>
  },
  // {
  //   title: 'Link',
  //   render: (el, row, index) => {
  //     return {
  //       children: <a style={{color: 'blue'}} onClick={() => {
  //         setSelectedSubSubGroupId(el.id)
  //         setShow('FILE')
  //       }}><u>Click Here</u></a>
  
  //     };
  //   },
  // },
];


const columns3 = [
  {
    title: 'Sr No',
    dataIndex: 'sr_no',
    key: 'sr_no',
    width: '20%',
    ...getColumnSearchProps('sr_no'),
    render: text => <b>{text}</b>
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: '60%',
    ...getColumnSearchProps('title'),
  },
  {
    title: 'View',
    render: (el, row, index) => {
      return {
        children: Array.isArray(el.upload_info) ? showImages(el.upload_info) : null
  
      };
    },
  },
];

  return (
    <div>

        <BreadCrumbNew>
          {(show == 'CRITERION' || show == 'SRNO' || show == 'FILE') && <a onClick={() => setShow('CRITERION')}>{groupName} ></a>} &nbsp; 
          {(show == 'SRNO' || show == 'FILE') && <a onClick={() => setShow('SRNO')}>{selectedSubGroupName} > </a>} 
          {(show == 'FILE') && <a onClick={() => setShow('FILE')}>{selectedSubSubGroupName}</a>} 
        </BreadCrumbNew>
      
      {show === 'CRITERION' && 
      <div>
        <Table pagination={false} columns={columns1} dataSource={data} />
        {/* <Table className="table table-sm table-bordered bg-white">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Criterion</TableCell>
            <TableCell>Metric Question</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data) && data.map((el,index) => 
            <TableRow key={index}>
              <TableCell>{index +1}</TableCell>
              <TableCell>{el.criterion}</TableCell>
              <TableCell>{el.metric_question}</TableCell>
              <TableCell><a style={{color: 'blue'}} onClick={() => {
                setSelectedSubGroupId(el.id)
                setShow('SRNO')
              }}><u>Click Here</u></a></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table> */}


      </div>}

      {!isLoaded && `Loading ....`}


      {show === 'SRNO' && <div>
        {/* <Button type="primary" onClick={() => 
          setSelectedSubGroupId('')
        }>Go Back</Button> */}
        
        <Table pagination={false} columns={columns2} dataSource={data1} />
        {/* <Table className="table table-sm table-bordered bg-white">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '10%'}}>Sr No</TableCell>
            <TableCell style={{ width: '80%'}}>Metric Question</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data1) && data1.map((el,index) => 
            <TableRow key={index}>
              <TableCell>{el.sr_no}</TableCell>
              <TableCell>{el.metric_question}</TableCell>
              <TableCell><a style={{color: 'blue'}} onClick={() => {
                setSelectedSubSubGroupId(el.id)
                setShow('FILE')
              }}><u>Click Here</u></a></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table> */}
      </div>}



      {show === 'FILE' && <div>
        {/* <Button type="primary" onClick={() => 
          setSelectedSubGroupId('')
        }>Go Back</Button> */}
        
        <Table pagination={false} columns={columns3} dataSource={data2} />
        {/* <Table className="table table-sm table-bordered bg-white">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '10%'}}>Sr No</TableCell>
            <TableCell style={{ width: '60%'}}>Title</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data2) && data2.map((el,index) => 
            <TableRow key={index}>
              <TableCell>{el.sr_no}</TableCell>
              <TableCell>{el.title}</TableCell>
              <TableCell>{Array.isArray(el.upload_info) ? showImages(el.upload_info) : null}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table> */}
      </div>}
    </div>
  )
}



function BreadCrumbNew({children}){
  return(
    <div style={{backgroundColor: 'blue', color: 'white', padding: 5, fontWeight: 700}}>
      {children}
    </div>
  )
}